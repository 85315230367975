@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --ql-text-color: rgba(255, 255, 255, 0.73);
    --app-bg-color: rgba(25, 26, 25, 1);
    --app-text-color: #fff;

    --text-field-bg: #fff;
}

html {
    min-height: 100%;
    background: var(--app-bg-color);
    color: var(--app-text-color);
}

.filepond--root,
.filepond--root .filepond--drop-label {
    height: 200px;
}

.filepond--credits {
    display: none;
}

#portal {
    position: relative;
    z-index: 999;
}

.rc-slider {
    height: 12px !important;
}

.rc-slider-tooltip-hidden {
    display: block !important;
}

.rc-slider-tooltip-arrow {
    display: none;
}

.rc-slider-tooltip-inner {
    background: transparent !important;
    color: #B5B5B5 !important;
    box-shadow: none !important;
    font-size: 16px !important;
}

.slick-next:before, .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #ffffff;
}

#geocoder-container .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon {
    top: 14px;
}

#geocoder-container .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
    height: 49px;
    color: var(--app-text-color);
}

#geocoder-container .mapboxgl-ctrl-geocoder--button {
    background: transparent;
}

#geocoder-container .mapboxgl-ctrl-geocoder--icon {
    fill: var(--app-text-color);
}

#geocoder-container .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: none;
    /*line-height: 49px;*/
    box-shadow: none;
    font-size: 18px;
    background: var(--text-field-bg);
    border: 1px solid #777;
    border-radius: 3px;
    cursor: pointer;;
    color: var(--app-text-color);
}

.instagram-media {
    max-width: none !important;
}

.ql-editor.ql-blank::before {
    color: var(--ql-text-color) !important;
}

.chrome-picker {
    background: #181818 !important;
    box-shadow: none !important;
    width: 100% !important;
}

.DraftEditor-root pre.public-DraftStyleDefault-pre {
    background: #fafafa;
    padding: 26px;
    display: inline-block;
    border: 1px solid #e7e7e7;
    font-size: 0.6em;
}

blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    border-left: 4px solid #cecece;
    margin-left: 0;
    padding-left: 40px;
}

.DraftEditor-root ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin-top: 0;
}

.DraftEditor-root ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin-top: 0;
}

.DraftEditor-root ol li, .DraftEditor-root ul li {
    margin: 7.5px 0 7.5px 15px;
    padding-left: 6px;
}

.DraftEditor-root ol ol, .DraftEditor-root ul, ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
}


.reset_text_styling p, .reset_text_styling span {
    font-size: 1.2rem;
}

.reset_text_styling h1, .reset_text_styling h1 span {
    line-height: normal;
    font-size: 3rem;
    margin-bottom: 0.6rem;
}

.reset_text_styling h2, .reset_text_styling h2 span {
    line-height: normal;
    font-size: 2.5rem;
    margin-bottom: 0.6rem;
}

.reset_text_styling h3, .reset_text_styling h3 span {
    line-height: normal;
    font-size: 2rem;
    margin-bottom: 0.6rem;
}

.reset_text_styling h4, .reset_text_styling h4 span {
    line-height: normal;
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
}

.reset_text_styling h5, .reset_text_styling h5 span {
    line-height: normal;
    font-size: 2.5rem;
    font-weight: 400;
    opacity: 0.9;
    margin-bottom: 0.6rem;
}

.reset_text_styling a {
    color: #06c;
}

.reset_text_styling a:hover {
    border-bottom: 1px solid currentColor;
}
